<template>
  <div class="modal-content">
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p class="subtitle has-text-primary">Update mileage</p>
        </div>
        <div class="column is-narrow">
          <a class="has-text-danger" @click="$modal.close">
            Close
          </a>
        </div>
      </div>
      <form class="container is-narrow" @submit.prevent="submit">
        <p class="subtitle is-6">
          Use the slider to select, or manually enter a new mileage value
        </p>
        <div class="columns is-multiline">
          <div class="column is-3">
            <input
              class="input is-rounded"
              type="number"
              max="500000"
              v-model="value"
              autofocus
            />
          </div>
          <div class="column">
            <VueSlider
              class="slider"
              dot-size="33"
              :min="0"
              :max="500000"
              :interval="10"
              v-model="value"
            />
          </div>
        </div>
        <div class="columns has-text-right">
          <div class="column">
            <span class="button is-text" @click.prevent="$modal.close">
              Cancel
            </span>
            <button
              type="submit"
              class="button is-info is-rounded"
              :class="{ 'is-loading': $wait.is('loading') }"
            >
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as companion from 'modules/companion/services'
import 'vue-slider-component/theme/antd.css'
export default {
  name: 'CompanionMileage',
  components: { VueSlider: () => import('vue-slider-component') },
  data: () => ({ value: null }),
  mounted() {
    this.value = this.mileage
  },
  computed: {
    ...mapGetters('companion', ['vrm', 'mileage', 'valuationDate', 'namaGrade'])
  },
  methods: {
    async submit() {
      try {
        this.$wait.start('loading')

        const { vrm, value: mileage, valuationDate: date, namaGrade } = this
        await companion.value({ vrm, mileage, namaGrade, date })
      } finally {
        this.$modal.close()
        this.$wait.end('loading')
      }
    }
  }
}
</script>
